@import '../../shared/settings.scss';

.menu-holder {
    a {
        color: $dark-grey;
    }
}

.themes-list {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid rgba($light-grey, 0.5);
    padding: 18px 0;
    margin: 0;
    list-style: none;

    li {
        margin: 0 8px 0 0;

        &:last-child {
            margin: 0;
        }

        .btn-medium {
            position: relative;
            padding: 3px 33px 3px 16px;
            text-align: left;

            & svg {
                position: absolute;
                top: 11px;
                right: 12px;
                transition: transform $fast ease;
                width: 5px;
                height: 8px;
            }

            &:hover {
                & svg {
                    transform: translateX(1px);
                    transition: transform $fast ease;
                }
            }
        }
    }
}

.tray-inner {
    .more-products {
        display: block;
        margin: 18px 0 28px;
        text-decoration: underline;
        line-height: 24px;
        color: $dark-grey;
        font-size: 14px;
    }

    @include respond-to(mobile) {
        .menu-holder {
            position: relative;
            padding: 0 20px 18px;
            ul {
                margin-top: 0;
                li {
                    border: none;
                }
            }
        }

        h4 {
            margin: 18px 0 4px;
        }
    }
}
