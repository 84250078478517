@import '../../shared/settings';
@import '../../mixins/scrollbar';
@import '../../mixins/inner';

$animation-speed: 300ms;
$sidebar-mobile-width: 90vw;
$sidebar-width: 540px;
$background-transparency: 0.6;
$breakpoint: 768px;

.tray-sidebar {
    h3 {
        position: sticky;
        top: 0;
        left: 0;
        margin: 0;
        border-bottom: 1px solid rgba($light-grey, 0.5);
        background: white;
        padding: 25px 20px;
        width: $sidebar-width - 40px;
        line-height: 26px;
        font-size: 16px;
        font-weight: bold;
        z-index: 2; // need to be above relative elements
    }

    @include respond-to(mobile) {
        h3 {
            padding: 14px 20px;
            line-height: 28px;

            @include respond-to-max($breakpoint) {
                width: calc(#{$sidebar-mobile-width} - 40px);
            }
        }
    }
}

.tray-sidebar {
    .tray-content {
        @include scrollbar; /* cannot hide scrollbar! for people without scrollwheel */
        position: relative;
        flex-grow: 1;
        background-color: white;
        width: $sidebar-width;

        @include respond-to-max($breakpoint) {
            width: $sidebar-mobile-width;
            overflow-x: hidden;
        }
    }

    .tray-close {
        position: fixed;
        top: 28px;
        left: $sidebar-width - 20px - 16px;
        z-index: 3;

        @include respond-to(mobile) {
            top: 18px;

            @include respond-to-max($breakpoint) {
                left: calc(#{$sidebar-mobile-width} - 20px - 16px);
            }
        }
    }

    /* This fires as soon as the element enters the dorm */
    &.tray-transition-enter,
    &.tray-transition-appear,
    &.tray-transition-exit-active {
        .tray-content {
            transition: transform $animation-speed;
        }
    }
}

.tray-sidebar-left {
    /* This fires as soon as the element enters the dorm */
    &.tray-transition-enter,
    &.tray-transition-appear {
        .tray-content {
            transform: translateX(-$sidebar-width);
            @include respond-to(mobile) {
                transform: translateX(-$sidebar-mobile-width);
            }
        }
    }

    /* This is where we can add the transition*/
    &.tray-transition-enter-active,
    &.tray-transition-appear-active {
        .tray-content {
            transform: translateX(0);
        }
    }

    /* This fires as soon as the showList is false */
    &.tray-transition-exit {
        .tray-content {
            transform: translateX(0);
        }
    }

    /* fires as element leaves the DOM*/
    &.tray-transition-exit-active {
        .tray-content {
            transform: translateX(-$sidebar-width);
            @include respond-to(mobile) {
                transform: translateX(-$sidebar-mobile-width);
            }
        }
    }
}

.tray-sidebar-right {
    align-items: flex-end;

    h3 {
        right: 0;
        left: auto;
    }

    .tray-close {
        right: 18px;
        left: auto;

        @include respond-to-max($breakpoint) {
            right: 18px;
            left: auto;
        }
    }

    /* This fires as soon as the element enters the dorm */
    &.tray-transition-enter,
    &.tray-transition-appear {
        .tray-content {
            transform: translateX($sidebar-width);
            transition: transform $animation-speed;

            @include respond-to-max($breakpoint) {
                transform: translateX($sidebar-mobile-width);
            }
        }
    }

    /* This is where we can add the transition*/
    &.tray-transition-enter-active,
    &.tray-transition-appear-active {
        .tray-content {
            transform: translateX(0);
        }
    }

    /* This fires as soon as the showList is false */
    &.tray-transition-exit {
        .tray-content {
            transform: translateX(0);
        }
    }

    /* fires as element leaves the DOM*/
    &.tray-transition-exit-active {
        .tray-content {
            transform: translateX($sidebar-width);
            @include respond-to-max($breakpoint) {
                transform: translateX($sidebar-mobile-width);
            }
        }
    }
}

.tray-top {
    &.tray-overlay {
        top: var(--site-top-height) !important;

        .tray-content {
            position: fixed;
            top: var(--site-top-height);
            right: 0;
            left: 0;
            max-height: calc(95vh - var(--site-top-height));
            overflow-y: scroll;
        }

        .tray-inner {
            display: block;
            margin: auto;
            padding: 0 24px;
            max-width: var(--site-width);
            box-sizing: border-box;
        }

        .tray-close {
            display: none;
        }
    }

    /* This fires as soon as the element enters the dorm */
    &.tray-transition-enter,
    &.tray-transition-appear,
    &.tray-transition-exit-active {
        .tray-content {
            transition: opacity $animation-speed;
        }
    }

    /* This fires as soon as the element enters the dorm */
    &.tray-transition-enter,
    &.tray-transition-appear {
        .tray-content {
            opacity: 0;
        }
    }

    /* This is where we can add the transition*/
    &.tray-transition-enter-active,
    &.tray-transition-appear-active {
        .tray-content {
            opacity: 1;
        }
    }

    /* This fires as soon as the showList is false */
    &.tray-transition-exit {
        .tray-content {
            opacity: 1;
        }
    }

    /* fires as element leaves the DOM*/
    &.tray-transition-exit-active {
        .tray-content {
            opacity: 0;
        }
    }
}

.tray-overlay {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    z-index: 999;
    background: rgba(black, $background-transparency);

    .tray-content {
        background-color: white;
    }

    .tray-close {
        z-index: 3;
        margin: 0;
        border-radius: 50%;
        background: $grey;
        padding: 0;
        width: 20px;
        height: 20px;

        svg {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 8px;
            height: 8px;
            fill: white;
        }

        &:hover {
            background: $dark-grey;
        }
    }
}

/* This fires as soon as the element enters the dorm */
.tray-transition-enter,
.tray-transition-appear {
    &.tray-overlay {
        background: rgba(black, 0);
        transition: background $animation-speed;
    }
}

/* This is where we can add the transition*/
.tray-transition-enter-active,
.tray-transition-appear-active {
    &.tray-overlay {
        background: rgba(black, $background-transparency);
    }
}

/* This fires as soon as the showList is false */
.tray-transition-exit {
    &.tray-overlay {
        background: rgba(black, $background-transparency);
    }
}

/* fires as element leaves the DOM*/
.tray-transition-exit-active {
    &.tray-overlay {
        background: rgba(black, 0);
        transition: background $animation-speed;
    }
}

.tray-form {
    padding: var(--gutter-size);

    p:first-child {
        margin-top: 0;
    }
}
