@import '../../shared/settings.scss';

.omni-search-dropdown {
    position: absolute;
    top: 46px;
    right: 0;
    left: 0;
    animation: drop-down-typeahead $slow cubic-bezier(0.1, 1.5, 0.35, 1);
    z-index: 999;
    border: 1px solid $light-grey;
    border-radius: 8px;
    box-shadow: 0 2px 4px 0 rgba(black, 0.09);
    background: white;
    line-height: normal;
    box-sizing: border-box;
    color: var(--dark-grey);

    @keyframes drop-down-typeahead {
        0% {
            transform: translateY(-10px);
        }
        100% {
            transform: translateY(0);
        }
    }

    .no-results {
        padding: 32px 0;
        text-align: center;

        .round {
            margin: 0 auto 12px;
            border-radius: 52px;
            background: $light-grey;
            width: 52px;
            height: 52px;

            svg {
                position: relative;
                top: 13px;
                width: 26px;
                height: 26px;
                fill: white;
            }
        }

        p {
            margin: 0;
            line-height: 24px;
            color: $grey;
            font-size: 15px;

            &.term {
                color: $dark-grey;
            }
        }
    }

    @include respond-to(mobile) {
        top: 47px;
        right: 0;
        left: 0;
        border: 0;
        border-bottom: 1px solid $light-grey;
        border-radius: 0;
        box-shadow: none;
    }
}

.omni-search-group-header {
    display: flex;
    flex-direction: row;
    font-weight: bold;

    small {
        font-size: inherit;
        font-weight: bold;
    }

    small,
    .bubble {
        margin-left: 4px;
    }

    .show-all {
        flex-grow: 1;
        text-align: right;
    }
}

.omni-search-item {
    a {
        display: grid;
        padding: 6px;
        grid-template-columns: 40px auto;
        grid-column-gap: 12px;
        text-decoration: none;
    }

    figure {
        display: block;
        width: 100%;
        height: 100%;
        min-height: 40px;

        svg {
            width: 36%;
        }
    }

    .omni-search-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 1px 0;
        min-width: 0;
    }

    .omni-search-title {
        @extend %nowrap;
        color: var(--dark-grey);
    }

    .omni-search-subtitle {
        @extend %nowrap;
        line-height: 16px;
        color: var(--grey);
        font-size: 12px;
    }

    &:hover,
    &.active {
        background: $lighter;
        cursor: pointer;

        .omni-search-title {
            color: $brand;
        }
    }
}

.omni-search-link {
    a {
        display: block;
        @extend %nowrap;
        color: var(--dark-grey);
    }
    small {
        color: var(--brand);
        font-size: 12px;
    }
    &:hover {
        background: $lighter;
        box-shadow: 0 0 0 4px  $lighter;

        a {
            text-decoration: none;
        }
    }
}

.omni-search-group {
    border-top: 1px solid $light-grey;
    padding: 16px;
    font-size: 14px;

    &:first-child {
        border-top: 0;
    }

    &.shops {
        ul {
            li {
                figure {
                    border-radius: 5px;
                    background-size: cover;
                }
            }
        }
    }


    ul {
        margin: 8px 0 0;
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 8px;
        line-height: 19px;

        li {
            overflow: hidden;

        }
    }

    .omni-search-more {
        display: block;
        margin-top: 6px;
        text-align: right;
        text-decoration: underline;
        color: var(--brand);
    }
}
