@import '../../shared/settings';
@import '../../shared/themes';

%badge-indicator {
    display: inline-block;
    margin: 0;
    border-width: 0;
    border-radius: 3px;
    padding: 7px 6px 6px;
    text-transform: uppercase;
    line-height: 12px;
    font-size: 12px;
    font-weight: 600;
}

%badge-counter {
    border-width: 1px;
    border-style: solid;
    border-radius: 3px;
    padding: 2px 2px 1px;
    min-width: 20px;
    height: 20px;
    box-sizing: border-box;
}

.badge {
    @extend %badge-indicator;
    border-color: var(--brand);
    background-color: var(--brand);
    color: white;
    svg {
        fill: white;
    }
}

small {
    &.badge {
        border-radius: 9px;
        padding: 0 6px;
        line-height: 18px;
        font-size: 11px;
        font-weight: bold;
    }
}

.badge-neutral {
    @extend %badge-indicator;
    border-color: $darker-grey;
    background-color: $darker-grey;
    color: white;
}

.badge-success {
    @extend %badge-indicator;
    border-color: var(--success);
    background-color: var(--success);
    color: white;
}

.badge-danger {
    @extend %badge-indicator;
    border-color: var(--danger);
    background-color: var(--danger);
    color: white;
}

.badge-warning {
    @extend %badge-indicator;
    border-color: var(--warning);
    background-color: var(--warning);
    color: white;
}

.badge-info {
    @extend %badge-indicator;
    border-color: $info;
    background-color: $info;
    color: white;
}

.badge-dark {
    @extend %badge-indicator;
    border-color: var(--dark-grey);
    background-color: var(--dark-grey);
    color: white;
}

.badge-counter {
    @extend %badge-counter;
    background-color: white;
    color: var(--brand);

    &.badge-warning {
        color: var(--warning);
    }

    &.badge-info {
        color: $info;
    }

    &.badge-success {
        color: var(--success);
    }

    &.badge-neutral {
        color: $darker-grey;
    }
}

.badge-christmas {
    background: $product-christmas;
}

.badge-black_friday {
    background: $product-black-friday;
}

.badge-saint_nicholas {
    background: $product-saint-nicholas;
}

.badge-new_years_eve {
    background: $product-new-years-eve;
}

.badge-feature {
    display: inline-flex;
    background-color: #ebf5fc;
    padding: 5px;
    height: 16px;
    width: 16px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;

    svg {
        width: 16px;
        height: auto;
        fill: var(--brand);
        display: block;
    }

    &.badge-warning {
        background-color: rgba($warning, 0.08);

        svg {
            fill: var(--warning);
        }
    }

    &.badge-info {
        background-color: rgba($info, 0.08);

        svg {
            fill: $info;
        }
    }

    &.badge-success {
        background-color: rgba($success, 0.08);

        svg {
            fill: var(--success);
        }
    }

    &.badge-neutral {
        background-color: rgba($darker-grey, 0.08);

        svg {
            fill: var($darker-grey);
        }
    }
}

small {
    &.badge-feature {
        border-radius: 3px;
        padding: 4px;
        height: 14px;
        width: 14px;

        svg {
            width: 14px;
        }
    }
}

.badge-loading {
    border-radius: 9px;
    padding: 4px;
    line-height: 9px;

    .loading {
        @include loading(white, white, 6px, 2px);
    }
}

.badge-bounce {
    animation-name: bounce;
    animation-duration: 1.5s;
    animation-timing-function: cubic-bezier(0.28, 0.84, 0.42, 1);
    animation-iteration-count: infinite;
}

@keyframes bounce {
    0% {
        transform: translateY(0);
    }
    25% {
        transform: translateY(0);
    }
    50% {
        transform: translateY(-3px);
    }
    75% {
        transform: translateY(1px);
    }
    100% {
        transform: translateY(0);
    }
}
