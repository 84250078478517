@import '../../mixins/respond';

.site-header {
    background-color: var(--site-top-background);
    color: white;

    .inner {
        height: var(--header-height);
        @include respond-to(mobile) {
            height: var(--header-height-mobile);
            padding: 0 var(--gutter-size);
        }
        > a {
            color: inherit;
            text-decoration: underline;
        }
    }

    .inline {
        display: flex;
        flex-direction: row;
        align-items: center;
        cursor: pointer;

        span {
            position: relative;
            display: block;
        }
        span + span {
            margin-left: var(--gutter-size);
        }

        .badge {
            position: absolute;
            top: -10px;
            right: -10px;
        }

        & > span > svg {
            fill: white;
            height: 24px;
            width: auto;
        }
    }

    .buttons {
        margin-left: auto;

        .inline {
            margin: 0 var(--gutter-size);

            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
    }

    .buttons,
    .branding {
        display: flex;
    }

    .search {
        flex: 1;
        position: relative;
    }

    .logo {
        display: block;
        svg {
            height: 44px;
            width: auto;
        }
    }

    .search {
        .searchbox {
            height: 54px;
            input[type='text'] {
                border-radius: 36px;
            }
        }
        .omni-search-dropdown {
            top: 58px;
        }
        .icon-search {
            stroke: var(--site-top-background);
        }
    }

    .buttons {
        display: flex;
        justify-content: flex-end;
    }

    @include respond-to(mobile) {
        .branding {
            .logo {
                svg {
                    height: 28px;
                }
            }

            .inline {
                margin: 0 var(--gutter-size);
                svg {
                    height: 14px;
                }
            }
        }
        .buttons {
            padding-right: var(--gutter-size);
            .inline {
                margin: 0 0 0 var(--gutter-size);
            }
        }
    }

    @include respond-to(not-mobile) {
        .branding {
            padding-right: calc(var(--gutter-size) * 4);
        }
        .buttons {
            padding-left: calc(var(--gutter-size) * 4);
        }
    }
}

.tray-sidebar .omni-search-dropdown {
    position: static;
}
