@import '../../shared/settings.scss';

.searchbox {
    position: relative;

    input[type='text'] {
        position: relative;
        padding: 10px 36px 10px 38px;
        height: 100%;
        text-indent: 0;
    }

    .pin {
        fill: var(--brand-offset);
    }

    .icon-search {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 13px;
        margin-right: 0;
        width: 24px;
        height: 24px;
        fill: var(--brand);
    }

    button {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;
        background: none;
        padding: 9px;
        width: 36px;
        height: 36px;

        svg {
            display: block;
            width: 16px;
            height: 16px;
            fill: $light-grey;
        }

        &:hover {
            svg {
                fill: $grey;
            }
        }
    }
}
