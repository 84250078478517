@use 'sass:math';

@import '../../shared/settings';

// social media colors
$twitter: #55acee;
$facebook: #3b5998;
$messenger: #0084ff;
$whatsapp: #4dc247;
$insagram: #f00075;

.location-action-links {
    .zupr-tooltip-holder {
        margin: 0 4px;
        display: inline-block;

        &:first-child {
            margin-left: 0;
        }
        &:last-child {
            margin-right: 0;
        }
    }
}

.share-on-social {
    display: flex;
    border: 1px solid $light-grey;
    border-radius: 5px;
    background: white;
    padding: 8px 10px;
    width: auto;

    div > * {
        margin: 0 3px;

        &:first-child {
            margin-left: 0;
        }

        &:last-child {
            margin-right: 0;
        }
    }
}

.btn-social {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    transition: background-color var(--transition-fast),
        border-color var(--transition-fast);
    border-radius: 100%;
    padding: 0;
    width: 35px;
    height: 35px;

    svg {
        transition: all var(--transition-fast);
        width: 50%;
        height: auto;
        fill: white;
    }
}

.social-link,
.location-link {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
    margin: 4px 0;

    .btn-social {
        margin-right: 8px;
    }
}

.social-link {
    .grid-block & {
        text-decoration: none;
    }
}

.btn-round {
    position: relative;
    transition: background-color var(--transition-fast);
    padding: 0;

    width: 35px;
    height: 35px;
}

.social-platform {
    background: white;
    border: 1px solid var(--border);

    width: 33px;
    height: 33px;

    svg {
        fill: var(--dark-grey);
    }

    &:hover,
    .location-link:hover & {
        background-color: var(--brand);
        border-color: var(--brand);

        svg {
            fill: white;
        }
    }
}

.social-facebook {
    background: $facebook;

    &:hover {
        background: darken($facebook, 10%);
    }

    svg {
        width: percentage(math.div(7px, 24px));
    }
}

.social-link-facebook {
    .grid-block p &:hover,
    &:hover {
        color: $facebook;
    }
}

.social-instagram {
    background: $insagram;

    &:hover {
        background: darken($insagram, 10%);
    }

    svg {
        width: percentage(math.div(12px, 24px));
    }
}

.social-link-instagram {
    .grid-block p &:hover,
    &:hover {
        color: $insagram;
    }
}

.social-twitter {
    background: $twitter;

    &:hover {
        background: darken($twitter, 10%);
    }

    svg {
        width: percentage(math.div(13px, 24px));
    }
}

.social-link-twitter {
    .grid-block p &:hover,
    &:hover {
        color: $twitter;
    }
}

.social-whatsapp {
    background: $whatsapp;

    &:hover {
        background: darken($whatsapp, 10%);
    }

    svg {
        width: percentage(math.div(13px, 24px));
    }
}

.social-link-whatsapp {
    .grid-block p &:hover,
    &:hover {
        color: $whatsapp;
    }
}

.social-messenger {
    background: $messenger;

    &:hover {
        background: darken($messenger, 10%);
    }

    svg {
        width: percentage(math.div(16px, 24px));
    }
}

.social-link-messenger {
    .grid-block p &:hover,
    &:hover {
        color: $messenger;
    }
}

.social-mail {
    background: $grey;

    &:hover {
        background: darken($grey, 10%);
    }

    svg {
        width: percentage(math.div(12px, 24px));
    }
}

.social-link-twitter {
    .grid-block p &:hover,
    &:hover {
        color: $grey;
    }
}
