@import '../../shared/settings';

.site-menu {
    background: var(--site-menu-background);

    .inner {
        height: var(--menu-height);
    }

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
        display: flex;
        position: relative;

        li {
            margin-right: 26px;
        }

        & + ul {
            margin-left: auto;
            li:last-child {
                margin-right: 0;
            }
        }
    }

    li {
        a {
            display: block;
            transition: color var(--fast);
            text-decoration: none;
            white-space: nowrap;
            color: var(--dark-grey);
            font-size: 16px;
            font-weight: var(--medium);
            line-height: var(--menu-height);

            &:hover {
                transition: color var(--fast);
                color: var(--brand);
            }
        }

        &.more {
            a {
                position: relative;
                padding-right: 15px;

                svg {
                    position: absolute;
                    top: 50%;
                    right: 0;
                    transform: translateY(calc(-50% + 1px));
                    transition: fill var(--fast);
                    width: 7px;
                    height: 5px;
                    fill: var(--dark-grey);
                }

                &:hover {
                    svg {
                        fill: var(--brand);
                    }
                }

                &.active {
                    svg {
                        transform: rotate(180deg);
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) {
    .mobile-menu-tray {
        .tray-close {
            position: fixed;
            top: 18px;
            left: calc(90vw - 20px - 20px);
        }

        a {
            &.logo {
                display: block;
                position: fixed;
                top: 0;
                z-index: 2;
                border-bottom: 1px solid rgba($light-grey, 0.5);
                background-color: white;
                padding: 12px 20px;
                width: calc(90vw - 20px - 20px);
                height: 32px;

                svg {
                    height: 32px;
                    width: auto;
                }

                // in case of text;
                color: var(--dark-grey);
                font-weight: bold;
                font-size: 21px;
                line-height: 32px;
            }
        }

        svg {
            &.logo {
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                width: 67px;
                height: 28px;
            }
        }

        .themes-list {
            margin: 0 -20px 0 0;
            border-bottom: 0;
            padding: 20px 0 6px;
            overflow: -moz-scrollbars-none;
            overflow-x: scroll;
            -ms-overflow-style: none;

            &::-webkit-scrollbar {
                display: none;
            }

            &::after {
                display: block;
                position: absolute;
                top: 0;
                right: 0;
                background: linear-gradient(
                    90deg,
                    rgba(white, 0) 0%,
                    white 100%
                );
                width: 40px;
                height: 72px;
                content: ' ';
            }

            li {
                .btn-medium {
                    background: transparent;
                    white-space: nowrap;
                }

                &:last-child {
                    display: flex;

                    &::after {
                        display: inline-block;
                        background-color: transparent;
                        width: 40px;
                        height: 32px;
                        content: ' ';
                    }
                }
            }
        }

        ul {
            margin: 56px 0 0;
            padding: 0;
            list-style: none;

            & + ul {
                margin-top: 0;
            }

            li {
                border-bottom: 1px solid rgba($light-grey, 0.5);
                line-height: 26px;
                font-size: 16px;

                .inline,
                a {
                    display: block;
                    border-left: 2px solid white;
                    padding: 14px 20px 14px 18px;
                    color: $dark-grey;
                    font-weight: var(--medium);

                    &.active {
                        border-color: $brand;
                    }
                }

                button {
                    &.inline {
                        position: relative;
                        background-color: white !important;
                        padding: 8px 20px 8px 33px;
                        width: 90vw;
                        line-height: 24px;
                        color: $brand;
                        font-size: 14px;

                        svg {
                            position: absolute;
                            top: 50%;
                            left: 20px;
                            transform: translateY(-50%) rotate(180deg);
                            width: 5px;
                            height: 8px;
                            fill: $brand;
                        }
                    }
                }

                &.more {
                    a {
                        position: relative;
                        padding-right: 13px;

                        svg {
                            position: absolute;
                            top: 50%;
                            right: 20px;
                            transform: translateY(-50%) rotate(-90deg);
                            width: 7px;
                            height: 5px;
                            fill: $dark-grey;
                        }
                    }
                }
            }
        }
    }
}
